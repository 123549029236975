import React from 'react'
import lottie from 'lottie-web';

import { ModuleWrapper } from '../moduleWrapper'

const LottieBlock = ({config, animation}) => {
    console.log(animation)

    const ref = React.useRef()

    React.useEffect(() => {
        const lottieAnimation = lottie.loadAnimation({
            container: ref.current,
            loop: true,
            autoplay: true,
            path: animation?.asset?.url,
            renderer: 'svg',
        });
    }, []);

  return (
    <ModuleWrapper {...config}>
        <div ref={ref} className='w-full'/>
    </ModuleWrapper>
  )
}

export default LottieBlock